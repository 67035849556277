import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaxCountOfBarcode,
  getProductsByNameOrCode,
  setSearchedProducts,
} from "../../../redux/product/productSlice";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import Barcode from "react-barcode";
import { CaretList, ProductUnitEnum } from "../../../actionTypes";
import BarcodePrint from "../../reports/BarcodePrint";
import { Image, StyleSheet } from "@react-pdf/renderer";
import bwipjs from "bwip-js";
import { Util } from "../../../util/Util";
import { makeGramToVori } from "../../purchase/create-purchase/ItemTable";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
const util = new Util();

const barcodeValue = (
  pname,
  msize,
  unitName, // New parameter for unit name
  showProductName,
  showMeasurementSize,
  showUnit,
  caret_id,
  caretList,
  sl,
  selectedOption
) => {
  let val = "";
  val += selectedOption && selectedOption.id;
  val += ",";
  val += "Name: ";
  if (showProductName) {
    val += pname;
    //if (showMeasurementSize || showUnit) val += ",";
  }
  val += ",Weight: ";
  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    let sonaton =
      selectedOption.weight_in_vori +
      " vori," +
      selectedOption.weight_in_ana +
      " ana," +
      selectedOption.weight_in_roti +
      " roti," +
      selectedOption.weight_in_point +
      " point.";

    val += sonaton;
    val += "/";
    val += util.convertToGram(
      selectedOption.weight_in_vori,
      selectedOption.weight_in_ana,
      selectedOption.weight_in_roti,
      selectedOption.weight_in_point
    );
    val += " grams.";
    //val += selectedOption.unit_name;
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "/";
    // val += " ";
    // val += selectedOption.unit_name;
    // val += " ";
    // val += selectedOption.unit_name;
    let digital = selectedOption.measurement_size + " grams.";
    val += digital;
  } else {
    val += msize + " " + unitName;
    if (showUnit) val += ",";
  }
  val += ",Caret: ";
  val +=
    caretList &&
    caretList.length > 0 &&
    caretList.find((x) => x.value == caret_id)
      ? caretList.length > 0 && caretList.find((x) => x.value == caret_id).label
      : "";
  val += ",SL#" + sl;
  return val;
};

const getMeasurement = (msize, unitName, showUnit, selectedOption) => {
  let val = "";

  const formatValue = (value) => {
    const integerPart = Math.floor(value);
    const decimalPart = parseFloat(value) - parseFloat(integerPart);
    const data =
      parseFloat(decimalPart) > 0
        ? parseFloat(value).toFixed(4)
        : Math.floor(value).toString();
    return data;
  };
  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    let sonaton =
      formatValue(selectedOption.weight_in_vori) +
      "V," +
      formatValue(selectedOption.weight_in_ana) +
      "A," +
      formatValue(selectedOption.weight_in_roti) +
      "R," +
      formatValue(selectedOption.weight_in_point) +
      "P,";

    val += sonaton;
    val += "(";
    val += util.convertToGram(
      selectedOption.weight_in_vori,
      selectedOption.weight_in_ana,
      selectedOption.weight_in_roti,
      selectedOption.weight_in_point
    );
    val += ")";
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "(";
    let digital = selectedOption.measurement_size + " )";
    val += digital;
  } else {
    val += msize + " " + unitName;
    if (showUnit) val += ",";
  }

  return val;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    gap: "10px",
    padding: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  productName: {
    width: 150,
  },
  productInfo: {
    fontSize: "8px",
    fontWeight: 400,
    marginRight: "10px",
    marginBottom: 20, // Adjust the space between product info and barcode
  },
  text: {
    fontSize: 8,
  },
  barcodeImage: {
    width: 50,
    height: 50,
  },
});
const PrintBarcode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const options = useSelector((state) => state.product.searchedProducts);
  const [selectedOption, setSelectedOption] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [showProductName, setShowProductName] = useState(true);
  const [showMeasurementSize, setShowMeasurementSize] = useState(true);
  // const [showSalableUnit, setShowSalableUnit] = useState(false);
  const [showUnit, setShowUnit] = useState(true); // New state for Unit checkbox
  const [openDialog, setOpenDialog] = useState(false);
  const [barcodeImage, setBarcodeImage] = useState([]);
  const max_count = useSelector((state) => state.product.max_count);
  const navigate = useNavigate();

  const printRef = React.useRef();
  useEffect(() => {
    dispatch(setSearchedProducts());
  }, []);
  const fetchData = async (searchTerm) => {
    if (searchTerm !== "") {
      setLoading(true);
      const response = await dispatch(
        getProductsByNameOrCode({ nameOrCode: searchTerm })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const generateBarcode = async (index) => {
      const canvas = document.createElement("canvas");
      await bwipjs.toCanvas(canvas, {
        bcid: "datamatrix", // Barcode type
        text: barcodeValue(
          selectedOption && selectedOption.product_name,
          selectedOption && selectedOption.measurement_size,
          selectedOption && selectedOption.unit_name,
          showProductName,
          showMeasurementSize,
          showUnit,
          selectedOption && selectedOption.caret_id,
          CaretList,
          max_count // + index + 1
        ),
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });
      const barcodeDataURL = canvas.toDataURL();
      return barcodeDataURL;
    };

    const generateAllBarcodes = async () => {
      const barcodeImages = [];
      for (let i = 0; i < quantity; i++) {
        const barcodeImage = await generateBarcode(i);
        barcodeImages.push(barcodeImage);
      }
      setBarcodeImage(barcodeImages);
    };

    generateAllBarcodes();
  }, [openDialog]);

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    setQuantity(1); // Reset quantity when option changes
    dispatch(
      getMaxCountOfBarcode({
        product_id: newValue && newValue.id,
      })
    );
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleProductNameToggle = () => {
    setShowProductName(!showProductName);
  };

  const handleMeasurementSizeToggle = () => {
    setShowMeasurementSize(!showMeasurementSize);
  };

  // const handleSalableUnitToggle = () => {
  //   setShowSalableUnit(!showSalableUnit);
  // };

  const handleUnitToggle = () => {
    setShowUnit(!showUnit);
  };

  const handlePrintButtonClick = () => {
    navigate("/barcode-print", {
      state: { selectedOption: selectedOption, max_count: max_count },
    });
    const generateBarcode = async (index) => {
      const canvas = document.createElement("canvas");
      await bwipjs.toCanvas(canvas, {
        bcid: "datamatrix", // Barcode type
        text: barcodeValue(
          selectedOption && selectedOption.product_name,
          selectedOption && selectedOption.measurement_size,
          selectedOption && selectedOption.unit_name,
          showProductName,
          showMeasurementSize,
          showUnit,
          selectedOption && selectedOption.caret_id,
          CaretList,
          max_count, // + index + 1,
          selectedOption
        ),
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });
      const barcodeDataURL = canvas.toDataURL();
      return barcodeDataURL;
    };

    const generateAllBarcodes = async () => {
      const barcodeImages = [];
      for (let i = 0; i < quantity; i++) {
        const barcodeImage = await generateBarcode(i);
        barcodeImages.push(barcodeImage);
      }
      setBarcodeImage(barcodeImages);
    };

    generateAllBarcodes();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div className="p-4">
      <Autocomplete
        freeSolo={true}
        options={options}
        loading={loading}
        getOptionLabel={(option) =>
          option.product_name +
          " - " +
          option.product_code +
          " - " +
          option.weight
        }
        onInputChange={(event, newInputValue) => {
          fetchData(newInputValue);
        }}
        onChange={handleOptionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Search Product")}
            placeholder={t("Search Product")}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {selectedOption && (
        <div className="my-6">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "40%" }}>Product Name</TableCell>
                  <TableCell sx={{ width: "30%" }}>Product Code</TableCell>
                  <TableCell sx={{ width: "30%" }}>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{selectedOption.product_name}</TableCell>
                  <TableCell>{selectedOption.product_code}</TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      value={quantity}
                      onChange={handleQuantityChange}
                      inputProps={{ min: 1 }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      <div className="my-6">
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={showProductName}
              onChange={handleProductNameToggle}
            />
          }
          label={t("Show Product Name")}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={showMeasurementSize}
              onChange={handleMeasurementSizeToggle}
            />
          }
          label={t("Show Measurement Size")}
        />

        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={showUnit}
              onChange={handleUnitToggle}
            />
          }
          label={t("Show Unit")}
        />
      </div>

      <div className="my-6">
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={handlePrintButtonClick}
        >
          {t("Print Barcodes")}
        </Button>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        sx={{ zIndex: 99999 }}
      >
        <DialogTitle>Barcodes</DialogTitle>
        <DialogContent>
          <div className="w-full flex items-center justify-center flex-col">
            {Array.from({ length: quantity }).map(
              (_, index) =>
                selectedOption && (
                  <div key={index} className="">
                    <div
                      className="flex items-center gap-2 text-10px w-[400px]"
                      ref={printRef}
                    >
                      <div
                        className="flex flex-col w-[150px] text-[10px]  text-left p-1"
                        style={{ wordBreak: "break-all" }}
                      >
                        {showProductName && (
                          <div className="w-[150px]">
                            <p className="text-[10px] whitespace-normal">
                              PN:
                              {showProductName && selectedOption.product_name}
                            </p>
                          </div>
                        )}
                        {showMeasurementSize && (
                          <div>
                            <p className="text-[10px] ">
                              W:
                              {getMeasurement(
                                selectedOption &&
                                  selectedOption.measurement_size,
                                selectedOption && selectedOption.unit_name,
                                showUnit,
                                selectedOption
                              )}
                              ,C:{" "}
                              {(selectedOption.caret_id &&
                                CaretList.find(
                                  (x) => x.value == selectedOption.caret_id
                                ).label) ||
                                ""}
                              ,SL:{max_count}
                              {/* + index + 1 */}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="bcode">
                        <Barcode
                          height={40}
                          value={selectedOption.id}
                          lineColor="#000000"
                          background="#FFFFFF"
                        />
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <BarcodePrint
            quantity={quantity}
            selectedOption={selectedOption}
            showProductName={showProductName}
            showMeasurementSize={showMeasurementSize}
            // showSalableUnit={showSalableUnit}
            showUnit={showUnit}
            CaretList={CaretList}
            max_count={max_count}
          /> */}

          {/* <Button type="button" onClick={handlePrint}>
            print
          </Button> */}

          <Button onClick={handleCloseDialog} color="error" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PrintBarcode;
