// src/redux/productSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  ATTACHMENT_CONTROLLER,
  CREATE_PRINT_COUNT_BARCODE_API,
  GET_DATA_FOR_STOCK_TRANSFER_API,
  GET_DATA_FOR_STOCK_TRANSFER_BY_PRODUCT_API,
  GET_STOCK_BY_WIREHOUSE_AND_PRODUCT_API,
  GET_STOCK_DETAILS_BY_PRODUCT_ID_API,
  PRODUCT_CONTROLLER,
  PRODUCT_PAGINATION_CONTROLLER,
  PRODUCT_SEARCH_CONTROLLER,
  STOCK_TRANSFER_API,
} from "../../actionTypes";

export const getProductsWithPagination = createAsyncThunk(
  "product/getProductsWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProduct = createAsyncThunk(
  "product/saveProduct",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;
      if (data && data.data) {
        const attRes = await makeApiCall(
          "post",
          `/${ATTACHMENT_CONTROLLER}`,
          { table_name: "products", reference_id: id },
          {}
        );
        // Merge the data arrays from both responses

        if (attRes && attRes.data && attRes.data.data) {
          data.data.data.Attachments =
            attRes.data.data.length > 0
              ? attRes.data.data.map((x) => {
                  return {
                    ...x,
                    OriginalName: x.original_name,
                    FilePath: x.url,
                    Size: x.size,
                  };
                })
              : [];
        }
      }
      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProductsByNameOrCode = createAsyncThunk(
  "product/getProductsByNameOrCode",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_SEARCH_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getStockData = createAsyncThunk(
  "product/getStockData",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}/${GET_STOCK_BY_WIREHOUSE_AND_PRODUCT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveBarcodePrintInfo = createAsyncThunk(
  "product/saveBarcodePrintInfo",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}/${CREATE_PRINT_COUNT_BARCODE_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getMaxCountOfBarcode = createAsyncThunk(
  "product/getMaxCountOfBarcode",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_CONTROLLER}/${CREATE_PRINT_COUNT_BARCODE_API}/${obj.product_id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getDataForStockTransfer = createAsyncThunk(
  "product/getDataForStockTransfer",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}/${GET_DATA_FOR_STOCK_TRANSFER_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getDataForStockTransferByProductId = createAsyncThunk(
  "product/getDataForStockTransferByProductId",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}/${GET_DATA_FOR_STOCK_TRANSFER_BY_PRODUCT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const createStockTransfer = createAsyncThunk(
  "product/createStockTransfer",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}/${STOCK_TRANSFER_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getStockDetails = createAsyncThunk(
  "product/getStockDetails",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CONTROLLER}/${GET_STOCK_DETAILS_BY_PRODUCT_ID_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    searchedProducts: [],
    searchedStocks: [],
    stockData: [],
    loading: false,
    error: null,
    max_count: 0,
  },
  reducers: {
    setSearchedProducts: (state, action) => {
      state.searchedProducts = [];
    },
    setSearchedStocks: (state, action) => {
      state.searchedStocks = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductsWithPagination.fulfilled, (state, action) => {
        state.products = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductsWithPagination.rejected, (state, action) => {
        state.products = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.products =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductsByNameOrCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductsByNameOrCode.fulfilled, (state, action) => {
        const data = action.payload.data.rows || [];
        state.searchedProducts = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductsByNameOrCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDataForStockTransfer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDataForStockTransfer.fulfilled, (state, action) => {
        const data = action.payload.data.rows || [];
        state.searchedStocks = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getDataForStockTransfer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDataForStockTransferByProductId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getDataForStockTransferByProductId.fulfilled,
        (state, action) => {
          const data = action.payload.data.rows || [];
          state.searchedStocks = data;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(getDataForStockTransferByProductId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStockData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStockData.fulfilled, (state, action) => {
        const data = action.payload.data.rows || [];
        state.stockData = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getStockData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMaxCountOfBarcode.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMaxCountOfBarcode.fulfilled, (state, action) => {
        const data = action.payload.data || 0;
        state.max_count = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getMaxCountOfBarcode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSearchedProducts, setSearchedStocks } = productSlice.actions;
export default productSlice.reducer;
