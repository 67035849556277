import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonDataGrid from "../../../components/datagrid/CommonDataGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getProductsWithPagination,
} from "../../../redux/product/productSlice";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";
import QuickPurchaseDialog from "./QuickPurchaseDialog";
import useScanDetection from "use-scan-detection";
import ScannerDialog from "./ScannerDialog";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { ROWS_PER_PAGE } from "../../../actionTypes";

const ProductList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const rowsPerPage = ROWS_PER_PAGE;
  const [anchorElMap, setAnchorElMap] = useState({});
  const [deleteStatus, setDeleteStatus] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [scannedData, setScannedData] = useState(null); // State to store scanned data

  const handleQuickPurchaseClick = (data) => {
    setSelectedProduct(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const handleClick = (event, rowData) => {
    setAnchorElMap((prevMap) => ({
      ...prevMap,
      [rowData.id]: event.currentTarget,
    }));
  };

  const handleClose = (rowData) => {
    setAnchorElMap((prevMap) => ({
      ...prevMap,
      [rowData.id]: null,
    }));
  };

  const renderActionsColumn = (rowData) => {
    return (
      <>
        <IconButton onClick={(event) => handleClick(event, rowData)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElMap[rowData.id]}
          open={Boolean(anchorElMap[rowData.id])}
          keepMounted
          onClose={() => handleClose(rowData)}
          sx={{
            boxShadow:
              "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
          }}
        >
          <MenuItem
            sx={{ fontSize: "14px" }}
            onClick={() => handleEditClick(rowData.id)}
          >
            {t("Edit")}
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "14px" }}
            onClick={() => handleDeleteClick(rowData.id)}
          >
            {t("Delete")}
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "14px" }}
            onClick={() => handleQuickPurchaseClick(rowData)}
          >
            {t("Quick Purchase")}
          </MenuItem>
        </Menu>
      </>
    );
  };
  const columns = [
    { columnName: "product_name", columnShow: "product_name" },
    { columnName: "product_code", columnShow: "product_code" },
    { columnName: "serial_no", columnShow: "serial_no" },
    { columnName: "weight", columnShow: "weight" },
    { columnName: "unit_name", columnShow: "unit_name" },
    {
      columnName: "actions",
      columnShow: "Actions",
      render: renderActionsColumn,
    },
  ];

  const cellFormatting = {
    product_name: {
      textAlign: "left",
    },
    product_code: {
      textAlign: "left",
    },
    weight: {
      textAlign: "left",
    },
    unit_name: {
      textAlign: "left",
    },
    actions: {
      width: "100px",
      textAlign: "center",
    },
  };
  const manipulateData = (data) => {
    // Perform data manipulation here
    return data.map((item) => ({
      ...item,
    }));
  };

  const handleEditClick = (id) => {
    navigate(`/product/${id}`, { replace: true });
  };

  const handleDeleteClick = async (id) => {
    const res = await dispatch(deleteProduct(id))
      .then((response) => {
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          toast.success(
            response && response.payload && response.payload.message
          );
          setDeleteStatus(true);
          //global?.window?.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error(error);
      });
  };

  const fetchData = async (page = 0, pageSize = rowsPerPage) => {
    const response = await dispatch(
      getProductsWithPagination({
        tableName: "product",
        page: page - 1,
        pageSize: pageSize,
        search: search,
      })
    );

    const data = (await response.payload) && response.payload.data;
    return {
      data: (data && data.rows) || [],

      total: (data && data.total.total) || 0,
      totalPages: (data && Math.ceil(data.total.total / rowsPerPage)) || 0,
    };
  };
  useEffect(() => {
    if (deleteStatus) {
      fetchData(1); // Fetch data again after deletion
      setDeleteStatus(false); // Reset delete status
    }
  }, [deleteStatus]);

  const [scannedId, setScannedId] = useState(null);
  useScanDetection({
    onComplete: setScannedData,
  });

  if (scannedData) {
    return navigate("/scan-product", {
      state: { data: scannedData },
      replace: true,
    });
  }

  return (
    <>
      <div className="p-4">
        <div className="w-full flex items-center justify-between mb-6">
          <Typography variant="h6">{t("Product List")}</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/product/new", { replace: true })}
          >
            {t("Add Product")}
          </Button>
        </div>
        <Input
          className="mb-6 max-w-sm"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search product..."
        />
        <CommonDataGrid
          columns={columns}
          fetchData={fetchData}
          cellFormatting={cellFormatting}
          dataManipulator={manipulateData}
        />
      </div>
      <QuickPurchaseDialog
        open={openDialog}
        onClose={handleCloseDialog}
        selectedProduct={selectedProduct}
      />
      {/* {scannedData && (
        <ScannerDialog
          open={Boolean(scannedData)}
          onClose={() => setScannedData(null)}
          scannedData={scannedData}
        />
      )} */}
    </>
  );
};

export default ProductList;
