import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useLocation } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { CaretList, ProductUnitEnum } from "../../../actionTypes";
import { Util } from "../../../util/Util";
import { makeGramToVori } from "../../purchase/create-purchase/ItemTable";
import { Button } from "@mui/material";
import { saveBarcodePrintInfo } from "../../../redux/product/productSlice";
import { useDispatch } from "react-redux";
const util = new Util();

const getMeasurement = (selectedOption) => {
  let val = "";
  const formatValue = (value) => {
    const integerPart = Math.floor(value);
    const decimalPart = parseFloat(value) - parseFloat(integerPart);
    const data =
      parseFloat(decimalPart) > 0
        ? parseFloat(Number(value).toFixed(4))
        : Math.floor(value).toString();
    return data;
  };
  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    let sonaton =
      formatValue(selectedOption.weight_in_vori) +
      "V," +
      formatValue(selectedOption.weight_in_ana) +
      "A," +
      formatValue(selectedOption.weight_in_roti) +
      "R," +
      formatValue(selectedOption.weight_in_point) +
      "P";

    val += sonaton;

    //val += selectedOption.unit_name;
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "(";
    // val += " ";
    // val += selectedOption.unit_name;
    // val += " ";
    // val += selectedOption.unit_name;
    let digital = selectedOption.measurement_size + " )";
    val += digital;
  }

  return val;
};

const getMeasurementDigital = (selectedOption) => {
  let val = "";
  const formatValue = (value) => {
    const integerPart = Math.floor(value);
    const decimalPart = parseFloat(value) - parseFloat(integerPart);
    const data =
      parseFloat(decimalPart) > 0
        ? parseFloat(Number(value).toFixed(4))
        : Math.floor(value).toString();
    return data;
  };
  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    val += "(";
    val += util.convertToGram(
      selectedOption.weight_in_vori,
      selectedOption.weight_in_ana,
      selectedOption.weight_in_roti,
      selectedOption.weight_in_point
    );
    val += ")";
    //val += selectedOption.unit_name;
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "(";
    // val += " ";
    // val += selectedOption.unit_name;
    // val += " ";
    // val += selectedOption.unit_name;
    let digital = selectedOption.measurement_size + " )";
    val += digital;
  }

  return val;
};
function pad(str, max) {
  return str.length < max ? pad("0" + str, max) : str;
}
const BarcodePrintPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const printRef = React.useRef();
  const { state } = location;
  const { selectedOption, max_count } = state;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      size: A4;
      margin: 0;
      fontSize:'12px !important'
    }
  `,
  });
  const updateBarcode = () => {
    Array.from({ length: 1 }).map((_, index) => {
      const obj = {
        print_count: max_count, // + index + 1,
        product_id: selectedOption.id,
        count_from: 0,
        count_to: 0,
      };
      dispatch(saveBarcodePrintInfo(obj));
    });
  };

  return (
    <div className="-mt-5">
      <div
        className="flex items-center gap-2 text-10px w-[400px] print-page"
        ref={printRef}
      >
        <div
          className="flex flex-col  w-[150px] ml-[80px] text-[10px]  text-left p-1 mt-0"
          style={{ wordBreak: "break-all" }}
        >
          <div className="w-[150px]">
            <p className="text-[13px] mt-2 font-bold whitespace-normal">
              PN:
              {state && state.selectedOption.product_name},W:
              {getMeasurement(state && state.selectedOption)}
              ,C:{" "}
              {(state &&
                state.selectedOption &&
                state.selectedOption.caret_id &&
                CaretList.find((x) => x.value == state.selectedOption.caret_id)
                  .label) ||
                ""}
              {/* ,SL:{max_count} */}
            </p>
          </div>
        </div>

        <div className="bcode mt-[10px]">
          <p className="text-xs font-semibold -mb-[30px] z-[99] text-center">
            {getMeasurementDigital(state && state.selectedOption)}
          </p>
          <Barcode
            margin={0}
            height={40}
            width={2}
            value={pad(
              state &&
                state.selectedOption &&
                state.selectedOption.id.toString(),
              5
            )}
            displayValue
            lineColor="#000000"
            background="transparent"
          />
          <p className="-mt-[45px] text-xs font-semibold text-center">
            SL:{max_count}
          </p>
        </div>
      </div>
      <Button
        type="button"
        className="print-btn"
        onClick={() => {
          handlePrint();
          updateBarcode();
        }}
      >
        print
      </Button>
    </div>
  );
};

export default BarcodePrintPage;
