export const BASE_URLL = "http://localhost:5000";
export const BASE_URL = "https://bo-gemshore.softshore.tech";
export const ALL_THEMES = "dark6, dark4, dark, light";

export const SESSION_TIMEOUT = 5;
export const ROWS_PER_PAGE = 10;
export const PurchaseStatusEnum = {
  Received: 1,
  Partial: 2,
  Pending: 3,
  Ordered: 4,
};
export const SellStatusEnum = {
  Delivered: 1,
  Ordered: 2,
};
export const ProductUnitEnum = {
  Vori: "4f7ca439-ff7e-467d-bd81-f37f0b12dcab",
  Gram: "a5fecbca-ff52-4dac-b76d-1c88123fa7f7",
  Pcs: "b674de24-b7b6-446f-8787-25768646e38a",
};

export const CaretList = [
  { label: "Deshi", value: 1 },
  { label: "18k", value: 2 },
  { label: "21k", value: 3 },
  { label: "22k", value: 4 },
];
export const PaidThroughList = [
  { label: "Cash", value: 1 },
  { label: "Nagad", value: 2 },
  { label: "Bkash", value: 3 },
  { label: "Card", value: 4 },
  { label: "Cheque", value: 5 },
  { label: "Online Banking", value: 6 },
];

export const UserTypeList = [
  { value: "owner", label: "Owner" },
  { value: "manager", label: "Manager" },
  { value: "shopkeeper", label: "Shopkeeper" },
];

export const AUTH_CONTROLLER = "auth";
export const VERIFY_LOGIN_API = "verifyLogin";
export const VERIFY_TOKEN_API = "verifyToken";
export const CHANGE_USER_API = "changeUser";
export const CHANGE_PASSWORD_API = "changePassword";

export const PRODUCT_CATEGORY_PAGINATION_CONTROLLER =
  "product-category-pagination";
export const PRODUCT_SUBCATEGORY_PAGINATION_CONTROLLER =
  "product-subcategory-pagination";
export const PRODUCT_UNIT_PAGINATION_CONTROLLER = "product-unit-pagination";
export const PRODUCT_PRICE_LEVEL_PAGINATION_CONTROLLER =
  "product-price-level-pagination";
export const PRODUCT_BRAND_PAGINATION_CONTROLLER = "product-brand-pagination";
export const PRODUCT_SELLING_METHOD_PAGINATION_CONTROLLER =
  "product-selling-method-pagination";
export const PRODUCT_PRICE_LEVEL_SETUP_PAGINATION_CONTROLLER =
  "product-price-level-setup-pagination";
export const BARCODE_SYMBOLOGY_PAGINATION_CONTROLLER =
  "barcode-symbology-pagination";
export const PRODUCT_PAGINATION_CONTROLLER = "product-pagination";
export const USER_PAGINATION_CONTROLLER = "user-pagination";
export const WIREHOUSE_PAGINATION_CONTROLLER = "wirehouse-pagination";
export const SUPPLIER_PAGINATION_CONTROLLER = "supplier-pagination";
export const RACK_PAGINATION_CONTROLLER = "rack-pagination";
export const PURCHASE_STATUS_PAGINATION_CONTROLLER =
  "purchase-status-pagination";
export const PURCHASE_PAGINATION_CONTROLLER = "purchase-pagination";
export const SELL_PAGINATION_CONTROLLER = "sell-pagination";
export const PAYMENT_STATUS_PAGINATION_CONTROLLER = "payment-status-pagination";
export const CUSTOMER_PAGINATION_CONTROLLER = "customer-pagination";

export const PRODUCT_CATEGORY_CONTROLLER = "product-category";
export const PRODUCT_SUBCATEGORY_CONTROLLER = "product-subcategory";
export const PRODUCT_UNIT_CONTROLLER = "product-unit";
export const PRODUCT_PRICE_LEVEL_CONTROLLER = "product-price-level";
export const PRODUCT_BRAND_CONTROLLER = "product-brand";
export const ATTACHMENT_CONTROLLER = "attachments";
export const PRODUCT_SELLING_METHOD_CONTROLLER = "product-selling-method";
export const PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER = "product-price-level-setup";
export const PRODUCT_CONTROLLER = "product";
export const BARCODE_SYMBOLOGY_CONTROLLER = "barcode-symbology";
export const PRODUCT_SEARCH_CONTROLLER = "product-search";
export const USER_CONTROLLER = "user";
export const WIREHOUSE_CONTROLLER = "wirehouse";
export const SUPPLIER_CONTROLLER = "supplier";
export const RACK_CONTROLLER = "rack";
export const RACK_GET_BY_WIREHOUSE_ID_API = "racksByWirehouse";
export const PURCHASE_STATUS_CONTROLLER = "purchase-status";
export const PURCHASE_CONTROLLER = "purchase";
export const SELL_CONTROLLER = "sell";
export const PAYMENT_STATUS_CONTROLLER = "payment-status";
export const GET_STOCK_BY_WIREHOUSE_AND_PRODUCT_API = "stocks";
export const GET_STOCK_DETAILS_BY_PRODUCT_ID_API = "stockDetails";
export const GET_PURCHASE_BY_BATCH_NO_API = "byBatch";
export const GET_SELL_BY_BATCH_NO_API = "byBatch";
export const GET_SELL_BY_BATCH_NO_AND_PRODUCT_API = "byBatchAndProduct";
export const GET_SELL_BY_PRODUCT_API = "byProduct";
export const PURCHASE_RETURN_CONTROLLER = "purchase-return";
export const SELL_RETURN_CONTROLLER = "sell-return";
export const CREATE_PRINT_COUNT_BARCODE_API = "barcode";
export const CUSTOMER_CONTROLLER = "customer";
export const SEARCH_CUSTOMER_API = "customer-search";
export const GET_DATA_FOR_STOCK_TRANSFER_API = "stock-transfer";
export const GET_DATA_FOR_STOCK_TRANSFER_BY_PRODUCT_API = "stock-transferbyid";
export const STOCK_TRANSFER_API = "stock-transfer/createStockTransfer";
export const BATCH_BY_PRODUCT_RACK_API = "search_batch";

export const REPORT_CONTROLLER = "reports";
export const SELL_SUMMARY_REPORT_API = "sell-summary";
export const SELL_REPORT_API = "sell";
export const DAILY_SELL_REPORT_API = "dailySell";
export const COMPANY_CONTROLLER = "company";
export const COMPANY_TERMS_API = "company-terms";
export const COMPANY_REPORT_API = "company-report";
export const COMPANY_API = "company";
export const CUSTOMER_REPORT_API = "customer-report";
export const STOCK_SUMMARY_REPORT_API = "stock-summary-report";
export const DASHBOARD_CONTROLLER = "dashboard";
export const MENU_CONTROLLER = "menu";

export const SECURITY_RULE_CONTROLLER = "security-rule";
export const SECURITY_GROUP_CONTROLLER = "security-group";
export const PAGINATION_API = "pagination";
