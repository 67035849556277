import React, { useState, useEffect } from "react";
import axios from "axios"; // For making HTTP requests
import {
  Button,
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { ROWS_PER_PAGE } from "../../actionTypes";

const CommonDataGrid = ({
  columns,
  fetchData,
  cellFormatting,
  dataManipulator,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Define responsive breakpoint

  useEffect(() => {
    fetchData(currentPage)
      .then((response) => {
        const manipulatedData = dataManipulator
          ? dataManipulator(response.data)
          : response.data;
        setData(manipulatedData);
        setTotal(response.total || 0);
        setTotalPages(response.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentPage, fetchData, dataManipulator]);

  const handlePageChange = (e, page) => {
    setCurrentPage(page + 1);
  };

  if (data && data.length === 0) {
    return <Typography variant="h6">{t("No Data Found")}</Typography>;
  }

  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        paddingBottom: "30px",
        overflowX: "auto", // Allow horizontal scroll on smaller screens
      }}
    >
      <div style={{ overflowX: isMobile ? "auto" : "unset" }}>
        <Table
          size="small"
          className="table-auto w-full mb-8"
          sx={{
            minWidth: isMobile ? "900px" : "unset", // Minimum width for mobile
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: (theme) => theme.palette.text.main,
                    width: column.width ? column.width : "auto", // Apply column width if provided
                    ...cellFormatting[column.columnName],
                  }}
                >
                  {column.columnShow.toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      color: (theme) => theme.palette.text.main,
                      width: column.width ? column.width : "auto", // Apply column width if provided
                      ...cellFormatting[column.columnName],
                    }}
                  >
                    {column.render
                      ? column.render(row)
                      : row[column.columnName]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <TablePagination
        colSpan={columns.length}
        count={total}
        rowsPerPage={ROWS_PER_PAGE}
        rowsPerPageOptions={[10, 25, 50]}
        page={currentPage - 1}
        onPageChange={handlePageChange}
        sx={{
          "& .MuiTablePagination-toolbar": {
            flexDirection: isMobile ? "column" : "row", // Stack pagination controls on mobile
            alignItems: isMobile ? "flex-start" : "center",
          },
        }}
      />
    </Paper>
  );
};

export default CommonDataGrid;
