import React, { useEffect, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonDataGrid from "../../../components/datagrid/CommonDataGrid";
import { useDispatch } from "react-redux";
import {
  deletePurchase,
  getPurchasesWithPagination,
} from "../../../redux/purchase/purchaseSlice";
import { Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";
import moment from "moment";
import { PurchaseStatusEnum, ROWS_PER_PAGE } from "../../../actionTypes";
import { useTranslation } from "react-i18next";
import { Input } from "antd";

const PurchaseList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rowsPerPage = ROWS_PER_PAGE;
  const [anchorElMap, setAnchorElMap] = useState({});
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [search, setSearch] = useState("");

  const handleClick = (event, rowData) => {
    setAnchorElMap((prevMap) => ({
      ...prevMap,
      [rowData.id]: event.currentTarget,
    }));
  };

  const handleClose = (rowData) => {
    setAnchorElMap((prevMap) => ({
      ...prevMap,
      [rowData.id]: null,
    }));
  };

  const renderActionsColumn = (rowData) => {
    return (
      <>
        <IconButton onClick={(event) => handleClick(event, rowData)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElMap[rowData.id]}
          open={Boolean(anchorElMap[rowData.id])}
          keepMounted
          onClose={() => handleClose(rowData)}
          sx={{
            boxShadow:
              "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
          }}
        >
          <MenuItem
            sx={{ fontSize: "14px" }}
            onClick={() => handleEditClick(rowData.id)}
          >
            {t("Edit")}
          </MenuItem>
          {rowData.purchase_status_id !=
            PurchaseStatusEnum.Received.toString() && (
            <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={() => handleDeleteClick(rowData.id)}
            >
              {t("Delete")}
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };
  const columns = [
    { columnName: "batch_number", columnShow: "batch_number" },
    { columnName: "purchase_date", columnShow: "purchase_date" },
    { columnName: "product_name", columnShow: "product_name" },
    { columnName: "weight", columnShow: "weight", width: "300px !important" },
    { columnName: "wirehouse_name", columnShow: "wirehouse_name" },
    { columnName: "supplier_name", columnShow: "supplier_name" },
    {
      columnName: "actions",
      columnShow: "Actions",
      render: renderActionsColumn,
    },
  ];

  const cellFormatting = {
    batch_number: {
      textAlign: "left",
    },
    purchase_date: {
      textAlign: "left",
    },
    actions: {
      width: "100px",
      textAlign: "center",
    },
  };
  const manipulateData = (data) => {
    // Perform data manipulation here
    return data.map((item) => ({
      ...item,
      purchase_date: moment(item.purchase_date).format("DD-MMM-YYYY"),
    }));
  };

  const handleEditClick = (id) => {
    navigate(`/purchase/${id}`, { replace: true });
  };

  const handleDeleteClick = async (id) => {
    const res = await dispatch(deletePurchase(id))
      .then((response) => {
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          toast.success(
            response && response.payload && response.payload.message
          );
          setDeleteStatus(true);
          //global?.window?.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error(error);
      });
  };

  const fetchData = async (page = 0, pageSize = rowsPerPage) => {
    const response = await dispatch(
      getPurchasesWithPagination({
        tableName: "purchase_master",
        page: page - 1,
        pageSize: pageSize,
        search: search,
      })
    );

    const data = (await response.payload) && response.payload.data;
    return {
      data: (data && data.rows) || [],

      total: (data && data.total.total) || 0,
      totalPages: (data && Math.ceil(data.total.total / rowsPerPage)) || 0,
    };
  };
  useEffect(() => {
    if (deleteStatus) {
      fetchData(1); // Fetch data again after deletion
      setDeleteStatus(false); // Reset delete status
    }
  }, [deleteStatus]);
  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between mb-6">
        <Typography variant="h6">{t("Purchase List")}</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/purchase/new", { replace: true })}
        >
          {t("Add Purchase")}
        </Button>
      </div>
      <Input
        className="mb-6 max-w-sm"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search purchase by product name..."
      />
      <CommonDataGrid
        columns={columns}
        fetchData={fetchData}
        cellFormatting={cellFormatting}
        dataManipulator={manipulateData}
      />
    </div>
  );
};

export default PurchaseList;
