import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useForm from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createStockTransfer,
  getDataForStockTransfer,
  setSearchedStocks,
} from "../../redux/product/productSlice";
import { getWirehouses } from "../../redux/wirehouse/wirehouseSlice";
import { getRacksByWirehouseId } from "../../redux/rack/rackSlice";
import _ from "../../util/lodash/lodash";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const defaultForm = {
  batch_number: "",
  id: "",
  wirehouse_id: "",
  transferred_wirehouse_id: "",
  rack_id: "",
  transferred_rack_id: "",
  total_quantity: 0,
  transfer_quantity: 0,
  ItemDetails: [],
};
const StockTransfer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);

  const searchedStocks = useSelector((state) => state.product.searchedStocks);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  //const rackList = useSelector((state) => state.rack.racks);
  const [rackList, setRackList] = useState([]);
  const [targetRackList, setTargetRackList] = useState();
  const [stockChildList, setStockChildList] = useState([]);

  useEffect(() => {
    dispatch(getWirehouses());
    dispatch(setSearchedStocks());
  }, []);
  useEffect(() => {
    if (searchedStocks && searchedStocks.length > 0) {
      const upForm = {
        ...form,
        ItemDetails: searchedStocks,
      };
      setForm(upForm);
    }
  }, [searchedStocks]);

  const handleSearchStock = async () => {
    await dispatch(getDataForStockTransfer(form));
  };
  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value))
        .then((res) => {
          const data = (res && res.payload && res.payload.data) || [];
          const comboData =
            data.length > 0 &&
            data.map((x) => {
              return {
                ...x,
                label: x.rack_name,
                value: x.id,
              };
            });
          setRackList(comboData);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid =
        form.batch_number !== "" &&
        form.wirehouse_id !== "" &&
        form.rack_id !== "";
      return isValid;
    }
    return false;
  };

  const handleSelectChange = (event, index) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (event.target.name === "target_wirehouse_id") {
      updateForm[index].target_wirehouse_id = event.target.value;
      dispatch(getRacksByWirehouseId(event.target.value))
        .then((res) => {
          const data = (res && res.payload && res.payload.data) || [];
          const comboData =
            data.length > 0 &&
            data.map((x) => {
              return {
                ...x,
                label: x.rack_name,
                value: x.id,
              };
            });
          setTargetRackList(comboData);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (event.target.name === "target_rack_id") {
      updateForm[index].target_rack_id = event.target.value;
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };
  const handleInputChange = (index, event) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (event.target.name === "transfer_quantity") {
      if (
        Number(event.target.value) > Number(updateForm[index].total_quantity)
      ) {
        updateForm[index].error =
          "Transfer quantity cannot exceed total quantity";
      } else {
        updateForm[index].error = null;
      }
      updateForm[index].transfer_quantity = Number(event.target.value);
      //updateForm[index].transfer_quantity = event.target.value;
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };
  const handleTransfer = async (e, item, index) => {
    const obj = {
      batch_number: item.batch_number,
      wirehouse_id: item.wirehouse_id,
      rack_id: item.rack_id,
      transfer_wirehouse_id: item.target_wirehouse_id,
      transfer_rack_id: item.target_rack_id,
      product_id: item.product_id,
      transfer_quantity: item.transfer_quantity,
    };
    const res = await dispatch(createStockTransfer(obj))
      .then((response) => {
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          toast.success(
            response && response.payload && response.payload.message
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error(error);
      });

    await dispatch(getDataForStockTransfer(form));
    window.location.reload();
  };

  return (
    <div className="p-4 ">
      <div className="w-full flex flex-col gap-5 items-start">
        <TextField
          label={t("Batch No")}
          name="batch_number"
          value={form.batch_number}
          onChange={handleChange}
          required
          error={form.batch_number === ""}
          placeholder="Enter Batch No to Search..."
          fullWidth
          margin="normal"
        />
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-4">
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Wirehouse")}</InputLabel>
          <Select
            name="wirehouse_id"
            label={t("Wirehouse")}
            value={form.wirehouse_id}
            onChange={handleSelect}
            error={form.wirehouse_id === ""}
          >
            {wirehouseList &&
              wirehouseList.length > 0 &&
              wirehouseList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Rack")}</InputLabel>
          <Select
            name="rack_id"
            label={t("Rack")}
            value={form.rack_id}
            onChange={handleSelect}
            error={form.rack_id === ""}
          >
            {rackList &&
              rackList.length > 0 &&
              rackList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="w-full flex flex-col md:flex-row items-start justify-start gap-4 my-5">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSearchStock}
          disabled={!canBeSubmit()}
        >
          {t("SEARCH STOCK")}
        </Button>
      </div>

      {form && form.ItemDetails.length > 0 ? (
        <TableContainer component={Paper}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                {/* Define table header cells */}
                <TableCell>Batch</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Wirehouse</TableCell>
                <TableCell>Rack</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Target Wirehouse</TableCell>
                <TableCell>Target Rack</TableCell>
                <TableCell>Target Quantity</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {form &&
                form.ItemDetails.length > 0 &&
                form.ItemDetails.map((item, index) => (
                  <TableRow key={index}>
                    {/* Render table items based on fetched data */}
                    <TableCell>{item.batch_number || ""}</TableCell>
                    <TableCell>{item.product_name || ""}</TableCell>
                    <TableCell>{item.wirehouse_name || ""}</TableCell>
                    <TableCell>{item.rack_name || 0}</TableCell>
                    <TableCell>{item.total_quantity || 0}</TableCell>
                    <TableCell>
                      {" "}
                      <Select
                        required
                        label="Target Wirehouse"
                        fullWidth
                        variant="standard"
                        id="target_wirehouse_id"
                        name="target_wirehouse_id"
                        value={`${item.target_wirehouse_id}`}
                        error={!item.target_wirehouse_id}
                        onChange={(event) => handleSelectChange(event, index)}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        {wirehouseList &&
                          wirehouseList.map((unit) => (
                            <MenuItem key={unit.value} value={unit.value}>
                              {unit.label}
                            </MenuItem>
                          ))}
                      </Select>
                      {/* </FormControl> */}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Select
                        required
                        label="Target Rack"
                        fullWidth
                        variant="standard"
                        id="target_rack_id"
                        name="target_rack_id"
                        value={`${item.target_rack_id}`}
                        error={!item.target_rack_id}
                        onChange={(event) => handleSelectChange(event, index)}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        {targetRackList &&
                          targetRackList.map((unit) => (
                            <MenuItem key={unit.value} value={unit.value}>
                              {unit.label}
                            </MenuItem>
                          ))}
                      </Select>
                      {/* </FormControl> */}
                    </TableCell>
                    <TableCell sx={{ width: "200px" }}>
                      <TextField
                        label="Target Quantity"
                        name="transfer_quantity"
                        type="number"
                        error={item.error || !item.transfer_quantity}
                        value={item.transfer_quantity}
                        onChange={(event) => handleInputChange(index, event)}
                        helperText={item.error}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: item.total_quantity,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={
                          !item.target_wirehouse_id ||
                          !item.target_rack_id ||
                          !item.transfer_quantity
                        }
                        onClick={(e) => handleTransfer(e, item, index)}
                      >
                        Transfer
                      </Button>
                    </TableCell>
                    {/* Add more cells if needed */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h4">{t("No Stock Found")}</Typography>
      )}
    </div>
  );
};

export default StockTransfer;
